import  React from "react"
import Layout from "../../components/layout"
import { Link } from 'gatsby'
import Head from '../../components/head'
import LatQuotesIntro from './LatQuotesIntro'

// markup
const LatQuotes_f = () => {
  return (
   <Layout >
      <Head title='Latin Quotes and Expressions - F'/>
     <h1>LATIN QUOTES AND EXPRESSIONS</h1>
     <h2>F</h2>
     <LatQuotesIntro />
    
     <p><b>Fabas indulcet fames</b> - Hunger sweetens the beans, or hunger makes everything taste good!</p>
<p><b>Faber est suae quisque fortunae</b> - Every man is the artisan of his own fortune. (Appius Claudius Caecus)</p>
<p><b>Faber quisque fortunae suae</b> - Each man (is) the maker of his own fortune</p>
<p><b>Facilis descensus averno</b> - The descent to Avernus (Hell) it's easy to fall, hard to rise</p>
<p><b>Facilius est multa facere quam diu</b> - It is easier to do many things than to do one for a long time. (Quintilianus)</p>
<p><b>Facilius per partes in cognitionem totius adducimur</b> - We are more easily led part by part to an understanding of the whole. (Seneca)</p>
<p><b>Facito aliquid operis, ut te semper diabolus inveniat occupatum</b> - Always do something, so that the devil always finds you occupied. (St. Jerome)</p>
<p><b>Facta, non verba</b> - Deeds, not words. (Actions speak louder than words)</p>
<p><b>Factum est</b> - It is done</p>
<p><b>Fallaces sunt rerum species</b> - The appearances of things are deceptive. (Seneca)</p>
<p><b>Falsus in uno, falsus in omnibus</b> - False in one thing, false in all</p>
<p><b>Fama crescit eundo</b> - The rumour grows as it goes. (Vergil)</p>
<p><b>Fama nihil est celerius</b> - Nothing is swifter than rumor</p>
<p><b>Fama semper vivat</b> - May his/her fame last forever</p>
<p><b>Fama volat</b> - The rumour has wings. (Vergil)</p>
<p><b>Fames est optimus coquus</b> - Hunger is the best cook</p>
<p><b>Fas est et ab hoste doceri</b> - It's proper to learn even from an enemy. (Ovid)</p>
<p><b>Favete linguis</b> - To keep a (religious) silence. (Horace)</p>
<p><b>Fax mentis incedium gloriae</b> - The passion of glory is the torch of the mind</p>
<p><b>Fecit (fec.)</b> - Made by</p>
<p><b>Felis qui nihil debet</b> - Happy [is] he who owes nothing</p>
<p><b>Felix culpa</b> - Happy fault</p>
<p><b>Felix qui potuit rerum cognoscere causas</b> - Happy is he who has been able to learn the causes of things. (Vergil)</p>
<p><b>Felo de se</b> - Suicide</p>
<p><b>Fere libenter homines id quod volunt credunt</b> - Men readily believe what they want to believe. (Caesar)</p>
<p><b>Festina lente</b> - Make haste slowly</p>
<p><b>Fiat justitia (et ruat caelum)</b> - "Let justice be done. (though the heavens fall)"""</p>
<p><b>Fiat lux</b> - Let there be light</p>
<p><b>Fiat voluntas tua</b> - Let Thy will [be done] (Biblical)</p>
<p><b>Fiat</b> - Let it be done</p>
<p><b>Fide, non armis</b> - By faith, not arms</p>
<p><b>Fidei defensor</b> - Defender of the faith</p>
<p><b>Fides punica</b> - Treachery. (Livy)</p>
<p><b>Fides quaerens intellectum</b> - Faith seeking understanding</p>
<p><b>Fidus Achates</b> - Faithful Achates (friend)</p>
<p><b>Filioque</b> - And from the son (a concept of Catholic Theology)</p>
<p><b>Finem respice</b> - Look to the end [before setting forth]</p>
<p><b>Finis coronat opus</b> - The ending crowns the work. (Ovid)</p>
<p><b>Finis</b> - The end</p>
<p><b>Flagrante delicto</b> - Caught red-handed, in the very act of a crime</p>
<p><b>Flamma fumo est proxima</b> - Flame follows smoke. (there is no smoke without fire) (Plautus)</p>
<p><b>Floreat regina regina</b> - May it flourish. (motto of the City of Regina, Saskatchewan Canada)</p>
<p><b>Floruit</b> - Flourished</p>
<p><b>Fluctuat nec mergitur</b> - It is tossed by the waves but it does not sink</p>
<p><b>Fons et origo</b> - The source and origin</p>
<p><b>Forsan et haec olim meminisse iuvabit</b> - Perhaps someday we will look back upon these things with joy</p>
<p><b>Forsan miseros meliora sequentur</b> - For those in misery perhaps better things will follow. (Virgil)</p>
<p><b>Fortes et liber</b> - Strong and free. (motto of Alberta)</p>
<p><b>Fortes fortuna adiuvat</b> - Fortune favors the brave. (Terence)</p>
<p><b>Fortiter fideliter forsan feliciter</b> - Bravely, faithfully, perhaps successfully</p>
<p><b>Fortiter in re, suaviter in modo</b> - Resolutely in action, gently in manner. (To do unhesitatingly what must be done but accomplishing it as inoffensively as possible)</p>
<p><b>Fortitudine vincimus</b> - By endurance we conquer</p>
<p><b>Fortius quo fidelius</b> - Strength through loyalty</p>
<p><b>Fortuna amicos parat, inopia amicos probat</b> - The fortune is preparing friends, the abundance is testing them</p>
<p><b>Fortuna caeca est</b> - Fortune is blind. (Cicero)</p>
<p><b>Fortuna vitrea est; tum cum splendet frangitur</b> - Fortune is glass; just when it gleams brightest it shatters</p>
<p><b>Frangar non flectar</b> - I am broken, I am not deflected</p>
<p><b>Frater, ave atque vale</b> - Brother, hello and good-bye. (Catullus)</p>
<p><b>Fronti nulla fides</b> - No reliance can be placed on appearance. (don't judge a book by its cover)</p>
<p><b>Frustra laborant quotquot se calculationibus fatigant pro inventione quadraturae circuli</b> - Futile is the labor of those who fatigue themselves with calculations to square the circle. (Michael Stifel, 1544)</p>
<p><b>Fugit hora</b> - The hour (i.e. time) flies</p>
<p><b>Fugit inreparabile tempus</b> - Irretrievable time flies. (Virgil)</p>
<p><b>Functus officio</b> - Having discharged his duty and thus ceased to have any authority over a matter</p>

   </Layout>
  )
}

export default LatQuotes_f
